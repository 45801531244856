<template>
  <CommonPages id="home" title="Wallpaper" classs="home">
    <section class="movie-container pc_padding">
      <div class="movie-content-continaer">
        <div class="movie-descript-common-row ">
          <div class="movie-descript-common-title">
            All Classic Movies
          </div>
          <div class="movie-descript-common-content">
            <div class="movie-early-row">
              <template v-for="(movie, index) in movieList.slice(1)">
                <div :key="`moview_${index}`" class="movie-early-item cursor" :style="{
         backgroundImage: `url(${movie.thumbnails[0]})`,
         backgroundSize: 'cover',
         backgroundPosition: 'center',
         backgroundRepeat: 'no-repeat'
       }" @click="gotoMovie(movie.dealTitle)">
                  <div class="moview-early-shadow" />
                  <div class="moview-early-date">
                    {{ formatDate(movie.recomDate) }}
                  </div>
                  <div class="moview-early-name">
                    {{ movie.title }}
                  </div>
                  <div class="moview-early-btn-row">
                    <div class="moview-early-btn cursor">
                      Read more
                    </div>
                  </div>
                </div>
                <div v-if="shouldInsertAd(index) !== -1 && deviceType == 'mobile'"
                  :key="`ad_${index}`" class="ad-inside-masonry ad-pos-row">
                  <AdComponent :ref="'adsRenderedList-' + index" class="m-b-20 m-t-20 pc_padding"
                    :ads="adsensConfig.all[shouldInsertAd(index)]" :showDebug="showDebug" />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>
  </CommonPages>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
import AdComponent from '@/pages/components/AdComponent.vue'
import CommonPages from '@/pages/components/pc_common.vue'
import '@/css/common.scss';
import '@/css/home.scss';

export default {
  name: 'Home',
  components: {
    AdComponent,
    CommonPages,
  },
  mixins: [common],
  data () {
    return {

      /////////
      movieList: [],
      hasNext: true,
      page: 1,
    };
  },
  metaInfo () {
    return {
      title: 'All Daily Classic Movie Recommendations - movienightguide.online',
      meta: [
        {
          name: 'description',
          content: 'Discover a classic movie every day at movienightguide.online. We provide detailed synopses, reviews, curated lists, and exclusive interviews to help you relive the golden age of cinema.',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
      "adsensConfig",
      'adPosConfig',
    ]),

  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
    this.loadAdSenseScript();  // 重新加载广告脚本

    this.getMovieList()
    // const { tag, imgid } = this.$route.params;

    this.onScroll = this.debounce(this.onScroll.bind(this), 300); // 使用防抖包装滚动事件
    window.addEventListener('scroll', this.onScroll);// 监听滚动事件
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
    // 组件销毁时移除滚动事件监听
    // window.removeEventListener('scroll', this.onScroll);
  },

  methods: {
    gotoMovie (name) {
      this.$router.push({ path: `/${name}` });
    },
    getCurrentDate () {
      const currentDate = new Date();

      // 获取浏览器的时区偏移
      const timeZoneOffset = currentDate.getTimezoneOffset();

      // 调整时间为当前时区
      const adjustedDate = new Date(currentDate.getTime() - timeZoneOffset * 60 * 1000);

      // 格式化为 yyyymmdd 格式
      const year = adjustedDate.getUTCFullYear();
      const month = String(adjustedDate.getUTCMonth() + 1).padStart(2, '0');
      const day = String(adjustedDate.getUTCDate()).padStart(2, '0');

      return `${year}${month}${day}`;
    },
    formatDate (dateString) {
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

      // 解析日期字符串
      // const year = dateString.slice(0, 4);
      const month = parseInt(dateString.slice(4, 6), 10) - 1; // 将月份转为从 0 开始的索引
      const day = dateString.slice(6, 8);

      // 格式化日期
      return `${months[month]} ${parseInt(day, 10)}`;
    },
    //shuju
    async getMovieList () {
      if (!this.hasNext) return
      try {
        const params = {
          pageNum: this.page,
          pageSize: 10,
          recomDate: this.getCurrentDate()
        };

        const res = await this.$global.server.getMovieList(params);
        console.log(res)
        if (res.code == 200) {
          let data = res.data.movies
          this.movieList.push(...data);
          if (this.loadADScriptSuccess) {
            this.displayAd('adsRenderedList-')
          } else {
            this.loadScriptConditionally()
          }
        }
        this.hasNext = res.data.hasNext
        this.page++;

      } catch (err) {
        console.log(err)
      }
    },

    // 防抖函数
    debounce (func, delay) {
      let timeout;
      return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    },
    onScroll () {
      // 获取文档的总高度
      const documentHeight = document.documentElement.scrollHeight;
      // 当前滚动的高度
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      // 视口的高度
      const windowHeight = window.innerHeight;

      // 当滚动距离接近底部2000px时触发
      if (scrollTop + windowHeight >= documentHeight - 2000) {
        console.log('加载');
        this.getMovieList();
      }
    },
    //广告相关start
    shouldInsertAd (index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.adPosConfig.all_m.indexOf(position);

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        this.$eventrack('adscript_loaded', 'expose');
        // this.loadAdWithDelay()
        this.scriptStatus()
        return; // 如果已加载，直接返回
      }
      await this.loadScriptConditionally();
    },
    loadScriptConditionally () {
      console.log(this.adsensConfig);
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        this.$eventrack('no_adscript_config', 'expose');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig.scriptUrl}"]`);
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsensConfig?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      this.$eventrack('adscript_add_success', 'expose');
      // script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
      script.onload = this.scriptStatus.bind(this); // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');

    },
    scriptStatus () {
      this.loadADScriptSuccess = true
    },
    loadAdWithDelay () {
      // setTimeout(() => {
      //   this.displayAd('adsRenderedList-');
      // }, 500);
      console.log('广告加载完成');

    },
    async displayAd (tag) {
      await this.$nextTick();

      // 获取所有 ads 元素的 refs，过滤掉非广告的 ref
      const adsElements = Object.entries(this.$refs)
        .filter(([key, ref]) => key.startsWith(tag) && ref !== undefined) // 排除 undefined 的 refs
        .flatMap(([, ref]) => ref); // 展开并获取所有元素

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(this.displayAd, 500); // 延迟再次尝试
        return;
      }

      console.log(adsElements); // 检查是否包含 <ins> 标签
      adsElements.forEach(ad => {
        console.log(ad); // 输出每个广告元素
        console.log('ready to push');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      });
    },
    //广告相关end

    //ciyi
    goto (name) {
      this.$router.push({ name: name });
    },

  }
};
</script>
